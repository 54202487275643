import React, { Component } from 'react';
//import {ReactComponent as ReactLogoPortrait} from './static/images/logoPortrait.svg';
//import {ReactComponent as ReactLogoLandscape} from './static/images/logoLandscape.svg';
import logoLandscapePng from './static/images/logoLandscape.png'; 
//import { useState } from 'react';
import logoFb from "./static/images/fb.png";
import logoYt from "./static/images/youtube01.png";
import logoTg from "./static/images/tg.png";
import logoEmail from "./static/images/email01.png";
import smile from "./static/images/smile.png";
import logoPortrait from "./static/images/Polexine_Logo_Portrait_01.png";
import frecciaBiancaSu from "./static/images/frecciaSuBianca.png";
import logoSc from "./static/images/soundCloud40x40.png";
import raise from "./static/images/raise.png";

const RIFERIMENTO_PAGINA_HOME='home';
const RIFERIMENTO_PAGINA_NOTIZIE='notizie';
const RIFERIMENTO_PAGINA_ARTICOLI='articoli';
const RIFERIMENTO_PAGINA_OLTRE='oltre';
const RIFERIMENTO_PAGINA_CANALI='canali';
const sezioniSito = [
  {riferimentoPagina:RIFERIMENTO_PAGINA_NOTIZIE,titolo:'Notizie',testo:'Notizie ed aggiornamenti dal Polesine.',hashtag:'#PolexineNotizie'}, 
  {riferimentoPagina:RIFERIMENTO_PAGINA_ARTICOLI,titolo:'Articoli',testo:'Articoli, analisi ed approfondimenti sui temi locali.',hashtag:'#PolexineArticoli'}, 
  {riferimentoPagina:RIFERIMENTO_PAGINA_OLTRE,titolo:'Oltre Il Fiume',testo:'Articoli, analisi ed approfondimenti sui temi non strettamente locali.',hashtag:'#PolexineOltreIlFiume'},
  {riferimentoPagina:RIFERIMENTO_PAGINA_CANALI,titolo:'Canali',testo:`Seguici su Facebook, su Telegram e su YouTube`,hashtag:'#Polexine'}
];

const contatti = {titolo:'Canali',testo:`Seguici su Facebook, su Telegram e su YouTube`,hashtag:'#Polexine'};

// qla logica è-> stile css collegateo al componente, da valutare in casi di riutilizzo
// import './App.css';
const classeQuandoMostriContenuto=`corpoCentrale rimuoviPadding`;

let _datiNotizie={};
let _datiArticoli={};
let _datiArticoliOltre={};

const getPromiseGetServerJson = (urlDaInvocare)=>{
  let myHeaders = new Headers();
  //da usare su server
  //https://developer.mozilla.org/en-US/docs/Web/API/Headers/append
  //vedi https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers
  myHeaders.append('Accept', 'application/json');
  myHeaders.append('credentials', 'same-origin');
  // myHeaders.append('pragma', 'no-cache');
  // myHeaders.append('cache-control', 'no-cache');
  const myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'same-origin',
    cache: 'default'
  };

  return fetch(urlDaInvocare, myInit);
};


// const effettuaGetServerJson = (urlDaInvocare,callbackOk,cosaFareSeKo)=>{
//   getPromiseGetServerJson(urlDaInvocare)
//   .then(res => res.json())
//   .then(result=>callbackOk(result), error=>cosaFareSeKo(error));
// };

const promiseGetNotizie = getPromiseGetServerJson('/dyn01');
const promiseGetArticoli = getPromiseGetServerJson('/dyn02');
const promiseGetArticoliOltre = getPromiseGetServerJson('/dyn03');


class ContenutoStandard extends Component{
  constructor(props) {
    super(props);
    this.state={classeCssContenitore:classeQuandoMostriContenuto};
    
    this.aggiornaPagina=this.aggiornaPagina.bind(this);
    this.aggiornaParent=props.aggiornaParent;

    this.cambiaStileContenitore=this.cambiaStileContenitore.bind(this);
  }

  aggiornaPagina = (paginaSuccessiva)=>{
    this.aggiornaParent(paginaSuccessiva);
  }

  cambiaStileContenitore = (stileDaApplicare)=>{
    this.setState({classeCssContenitore:stileDaApplicare});
  }
}

class Header extends Component{
  render(){
     return <header>
        <img src={logoLandscapePng} className="headerLogoPng" alt="#Polexine"></img>
     </header>
   }
}

class Footer  extends ContenutoStandard{
  render(){
    function creaNodoDaLista(sezione, funz1){
      return  <div className="sezioneFooter">
                  <div className="cntSezione" onClick={()=>{window.scrollTo(0,0);setTimeout(()=>funz1(sezione.riferimentoPagina),350);}}>
                      <div>
                          <h1 className="titoloSezione radiusTestoSezione">{sezione.testo}</h1>
                      </div>
                  </div>
              </div>
    }
     return <footer>
        <div className="sezioneFooter">
          <h6>Privacy/Cookie Policy</h6>
          <div className="sezioneRaise">
            <img src={raise} alt="#Polexine" onClick={()=>{window.open('https://polexine.net/privacy/generale','_blank')}}/>
          </div>
        </div>
        <div className="sezioneFooter" onClick={()=>{window.scrollTo(0,0);setTimeout(()=>this.aggiornaPagina(RIFERIMENTO_PAGINA_HOME),350);}}>
          <h6>Home Page</h6>
          <div className="sezioneHome">
            <img src={logoPortrait} alt="#Polexine"/>
          </div>
        </div>
        <div className="sezioneFooter">
          <h6>Canali Web</h6>
          <SezioneSocial/>
        </div>
        <div className="sezioneFooter">
          <div className="cntSezione" onClick={()=>{window.scrollTo(0,0);setTimeout(()=>this.aggiornaPagina(RIFERIMENTO_PAGINA_NOTIZIE),350);}}>
              <div>
                  <h1 className="titoloSezione radiusTestoSezione">Notizie</h1>
              </div>
          </div>
        </div>
        <div className="sezioneFooter">
          <div className="cntSezione" onClick={()=>{window.scrollTo(0,0);setTimeout(()=>this.aggiornaPagina(RIFERIMENTO_PAGINA_ARTICOLI),350);}}>
              <div>
                  <h1 className="titoloSezione radiusTestoSezione">Articoli</h1>
              </div>
          </div>
        </div>
        <div className="sezioneFooter">
          <div className="cntSezione" onClick={()=>{window.scrollTo(0,0);setTimeout(()=>this.aggiornaPagina(RIFERIMENTO_PAGINA_OLTRE),350);}}>
              <div>
                  <h1 className="titoloSezione radiusTestoSezione">Oltre il fiume</h1>
              </div>
          </div>
        </div>
     </footer>
   }
}

class Caricamento extends Component{
  render(){
    // const divCaricamentoStyle = {
    //   display: 'block', 
    //   width: '100%',
    //   height: '100%',
    //   top: 0,
    //   left: 0,
    //   right: 0,
    //   bottom: 0,
    //   backgroundColor: 'rgba(0,0,0,0.5)',
    //   zIndex: 9999,
    //   cursor: 'pointer'
    // };
    return <div className="cntCaricamento">
          <img src={logoPortrait} className="spinnerCaricamento" alt="#Polexine"></img>
    </div>

  }
}

class Home extends ContenutoStandard{
  constructor(props) {
    super(props);
  }

  //capire perchè non riesco più a riferirmi a this.state={classeCssContenitore:classeQuandoMostriContenuto}; nella classe

  render(){
      function creaNodoDaLista(sezione, funz1){
        let nodoSocial = sezione.riferimentoPagina===RIFERIMENTO_PAGINA_CANALI?<SezioneSocial/>:null;
        let paginaRelativa= sezione.riferimentoPagina===RIFERIMENTO_PAGINA_CANALI?RIFERIMENTO_PAGINA_HOME:sezione.riferimentoPagina;
        return  <div key={"idNodoHome"+Math.random().toString()} className='sezioneTematicaHome' onClick={()=>{window.scrollTo(0,0);setTimeout(()=>funz1(paginaRelativa),350);}}>
                    <div className="sezioneTematicaHomeTitolo">
                      <h2>{sezione.titolo}</h2>
                    </div>
                    <div className="sezioneTematicaHomeTesto">
                      <p>{sezione.testo}</p>
                    </div>
                    <div className="sezioneTematicaHomeHashtag">
                      <h4>{sezione.hashtag}</h4>
                    </div>
                    {nodoSocial}
                </div>
      }
     return <div id="idHome" className={'corpoCentrale rimuoviPadding'}>
              {
                sezioniSito.map((sezione)=>creaNodoDaLista(sezione,this.aggiornaPagina))
              }
            </div>
   }
}

/*
class ContenutoSeparato extends ContenutoStandard{
  //'#fdd000'
  constructor(props) {
    super(props);

    // let getContenuti = ()=>{return sezioniSito;}; 


    // this.state={
    //   contatoreContenuti:0,
    //   contenuti:getContenuti(),
    //   contatoreStile:0,
    //   coloriSfondo:['#99ccff'],
    //   famiglieFont:['Arial, Helvetica, sans-serif', 'monospace,monospace;'],
    //   posizioneStringa:1
    // };

    this.state={
      contatoreContenuti:0,
      contenuti:[contatti],
      contatoreStile:0,
      coloriSfondo:['#99ccff'],
      famiglieFont:['Arial, Helvetica, sans-serif', 'monospace,monospace;'],
      posizioneStringa:1
    };
  }
  componentDidMount() {
    // this.timerContenuto = setInterval(
    //   () => {
    //     this.setState({
    //       contatoreContenuti:this.state.contatoreContenuti < this.state.contenuti.length - 1 ? this.state.contatoreContenuti + 1 : 0,
    //       posizioneStringa:1,
    //       contatoreStile: this.state.contatoreStile < this.state.coloriSfondo.length - 1 ? this.state.contatoreStile + 1 : 0
    //     });
    //   },
    //   8000
    // );
    // this.posizioneStringa=1;
    // this.timerDigitaTesto = setInterval(()=>{
    //   if(this.state.posizioneStringa< this.state.contenuti[this.state.contatoreContenuti].testo.length){
    //      let ix=this.state.posizioneStringa+1;
    //      this.setState({posizioneStringa:ix});
    //   }
    // },100);
    //<p>{this.state.contenuti[this.state.contatoreContenuti].testo.substring(0,this.state.posizioneStringa)}</p>
  }
  componentWillUnmount() {
    // clearInterval(this.timerContenuto);
    // clearInterval(this.timerDigitaTesto);
  }
  render(){
    let sezioneSocial= this.state.contenuti[this.state.contatoreContenuti].titolo==='Canali'?<SezioneSocial/>:null;

    return <div className="cntContenutoSeparato radiusTestoSezione" style={{backgroundColor:this.state.coloriSfondo[this.state.contatoreStile]
    }}>
      <div className="contenutoSeparato">
        <h2 className="sezione">{this.state.contenuti[this.state.contatoreContenuti].titolo}</h2>
        <div>
          <p>{this.state.contenuti[this.state.contatoreContenuti].testo}</p>
        </div>
        <div className="sezioneHashtagDinamico">
          <h4>{this.state.contenuti[this.state.contatoreContenuti].hashtag}</h4>
        </div>
        <div>{sezioneSocial}</div>
      </div>
    </div>
  }
}
*/

class Notizie extends ContenutoStandard{
  constructor(props) {
    super(props);
    // non fare l'override dello stato qui, altrimenti perde le proprietà dalla classe madre
    // this.state={
    //   error: null,
    //   isLoaded: false,
    //   items: []
    // };
  }
  componentDidMount() {
    const callbackOk = (result) => {
      _datiNotizie= result;
      this.setState({
        isLoaded: true,
        items: result.notizie
      });
    };

    const cosaFareSeKo = (error) => {
      this.setState({
        isLoaded: true,
        error: error
      });
    };

    if(_datiNotizie.hasOwnProperty('notizie')){
      this.setState({
        isLoaded: true,
        items: _datiNotizie.notizie
      });
    } else {
      promiseGetNotizie.then(res => res.json()).then(result=>callbackOk(result), error=>cosaFareSeKo(error));
    }
  }

  render() {
    const { error, isLoaded, items } = this.state;

    function creaNodoHtmlTesto(testo){
      let salvaTestoOriginale=testo;
      try {
        const ricorrenze=testo.match(/(https?):\/\/[^\s$.?#].[^\s]*/gm);
        if(Array.isArray(ricorrenze)){
            let ricorrenzeNoDoppi= new Set();
            ricorrenze.forEach((e)=>{
              if(!ricorrenzeNoDoppi.has(e)){
                ricorrenzeNoDoppi.add(e);
              }
            });
            ricorrenzeNoDoppi.forEach((r)=>{
              let trattaUrl = (urlInput, carattereDaTrattare)=>{
                if(urlInput && carattereDaTrattare){
                  urlInput=urlInput.trim();
                  let indice= urlInput.lastIndexOf(carattereDaTrattare);
                  switch(carattereDaTrattare){
                    case ':':
                      if (indice!==4 && indice!==5){
                        urlInput=urlInput.substring(0,indice);
                      }
                      break;
                    default:
                      //console.log('niente');
                      break;
                  }
                }
                return urlInput;
              }
              let elab01=trattaUrl(r,':');
              testo=testo.replaceAll(r,`<br><a href=\"${elab01}\" target=\"_blank\"><b>${elab01}</b></a><br>`);
            });
        }
        //console.log(testo);
        return {__html: testo};
      } catch (e){
        console.log(e);
        return {__html: salvaTestoOriginale};
      }
    }

    if (error) {
      return <div id="idNotizie" className={this.state.classeCssContenitore}>
                <div className="cntSezione tolgoSpazioInBasso"  onClick={()=>this.aggiornaPagina('home')}>
                  <h1 className="titoloSezione radiusTestoSezione">Notizie</h1>
                  <div>{error.message}</div>
                </div>
             </div>;
    } else if (!isLoaded) {
      return <div id="idNotizie" className={this.state.classeCssContenitore}>
                <div className="cntSezione tolgoSpazioInBasso"  onClick={()=>this.aggiornaPagina('home')}>
                  <h1 className="titoloSezione radiusTestoSezione">Notizie</h1>
                  <Caricamento/>
                </div>
             </div>;
    } else {
      return (
        <div id="idNotizie" className={this.state.classeCssContenitore}>
          <div className="cntSezione tolgoSpazioInBasso">
            <h1 className="titoloSezione radiusTestoSezione" onClick={()=>this.aggiornaPagina('home')}>Notizie</h1>
            <div className="iconaHome" onClick={()=>{window.scrollTo(0,0);setTimeout(()=>this.aggiornaPagina('home'),850);}}><img src={logoPortrait} alt="#Polexine"></img></div>
            <div className="iconaFrecciaSu" onClick={()=>window.scrollTo(0,0)}><img src={frecciaBiancaSu} alt="#Polexine"></img></div>
              {items.map(item => (
                <div className="cntNotizia" key={item.id}>
                  <h3>{item.titolo}</h3>
                  <p dangerouslySetInnerHTML={creaNodoHtmlTesto(item.testo)}/>
                </div>
              ))}
          </div>
        </div>
      );
    }
  }
}

function creaNodoHtmlArticoloSezioneHastag(item){
  let lista="";
  if(Array.isArray(item.listaTag)){
    item.listaTag.forEach(hT=>lista+='<p>'+hT.trim()+'</p>');
  }
  return {__html: `<div class="sezioneHashtag">${lista}</div>`}
}
function creaNodoHtmlArticoloImmagine(item){
  return {__html: `<img src="${item.sezioneAnteprima}" alt="${item.alt}"</img>`}
}

class Articoli extends ContenutoStandard{
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const callbackOk = (result) => {
      _datiArticoli= result;
      this.setState({
        isLoaded: true,
        items: result.articoli
      });
    };

    const cosaFareSeKo = (error) => {
      this.setState({
        isLoaded: true,
        error: error
      });
    };

    if(_datiArticoli.hasOwnProperty('articoli')){
      this.setState({
        isLoaded: true,
        items: _datiArticoli.articoli
      });
    } else {
      promiseGetArticoli.then(res => res.json()).then(result=>callbackOk(result), error=>cosaFareSeKo(error));
    }
  }

  render(){
    const { error, isLoaded, items } = this.state;
    return  <div id="idArticoli" className="corpoCentrale rimuoviPadding">
                <div className="cntSezione">
                  <h1 className="titoloSezione radiusTestoSezione" onClick={()=>this.aggiornaPagina('home')}>Articoli</h1>
                  <div className="iconaHome" onClick={()=>{window.scrollTo(0,0);setTimeout(()=>this.aggiornaPagina('home'),850);}}><img src={logoPortrait} alt="#Polexine"></img></div>
                  <div className="iconaFrecciaSu" onClick={()=>window.scrollTo(0,0)}><img src={frecciaBiancaSu} alt="#Polexine"></img></div>
                  { (error && <div>{error.message}</div>)
                    ||
                    (!isLoaded && <Caricamento/>)
                    ||
                    (isLoaded && (items.map((item,ix,array) => (
                      <div key={ix} className="previewArticolo" onClick={()=>window.open(item.open, '_blank')}>
                        <div dangerouslySetInnerHTML={creaNodoHtmlArticoloSezioneHastag(item)}/>
                        <div className="sezioneImmagineAnteprima">
                          <div dangerouslySetInnerHTML={creaNodoHtmlArticoloImmagine(item)}/>
                        </div>
                        <h3>{item.titolo}</h3>
                        <p>{item.descrizione}</p>
                      </div>
                    ))))
                  }
                </div>
            </div>;
  }
}

class Oltre extends ContenutoStandard{
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const callbackOk = (result) => {
      _datiArticoliOltre= result;
      this.setState({
        isLoaded: true,
        items: result.articoliOltre
      });
    };

    const cosaFareSeKo = (error) => {
      this.setState({
        isLoaded: true,
        error: error
      });
    };

    if(_datiArticoliOltre.hasOwnProperty('articoliOltre')){
      this.setState({
        isLoaded: true,
        items: _datiArticoliOltre.articoliOltre
      });
    } else {
      promiseGetArticoliOltre.then(res => res.json()).then(result=>callbackOk(result), error=>cosaFareSeKo(error));
    }
  }

  render(){
    const { error, isLoaded, items } = this.state;
    return <div id="idOltre" className="corpoCentrale rimuoviPadding">
      <div className="cntSezione">
        <h1 className="titoloSezione radiusTestoSezione" onClick={() => this.aggiornaPagina('home')}>Oltre il Fiume</h1>
        <div className="iconaHome" onClick={() => { window.scrollTo(0, 0); setTimeout(() => this.aggiornaPagina('home'), 850); }}><img src={logoPortrait} alt="#Polexine"></img></div>
        <div className="iconaFrecciaSu" onClick={() => window.scrollTo(0, 0)}><img src={frecciaBiancaSu} alt="#Polexine"></img></div>
        { (error && <div>{error.message}</div>)
            ||
          (!isLoaded && <Caricamento/>)
            ||
          (isLoaded && (items.map((item,ix,array) => (
            <div key={ix} className="previewArticolo" onClick={()=>window.open(item.open, '_blank')}>
              <div dangerouslySetInnerHTML={creaNodoHtmlArticoloSezioneHastag(item)}/>
              <div className="sezioneImmagineAnteprima">
                <div dangerouslySetInnerHTML={creaNodoHtmlArticoloImmagine(item)}/>
              </div>
              <h3>{item.titolo}</h3>
              <p>{item.descrizione}</p>
            </div>
          ))))
        }
      </div>
    </div>
  }
}

class SezioneSocial extends ContenutoStandard{
  constructor(props) {
    super(props);
  }

  render(){
    return <div className="sezioneSocial">
            <a href="https://www.facebook.com/PolexineNews" target="_blank" rel="noreferrer"><img src={logoFb} alt="#Polexine"/></a>
            <a href="https://www.youtube.com/channel/UCX4c1CLQtECpByWB6fWdAEA" target="_blank" rel="noreferrer"><img src={logoYt} alt="#Polexine"/></a>
            <a href="https://www.t.me/polexine" target="_blank" rel="noreferrer"><img src={logoTg} alt="#Polexine"/></a>
            <a href="https://soundcloud.com/user-888601233" target="_blank" rel="noreferrer"><img src={logoSc} alt="#Polexine"/></a>
            <a href="mailto:polexine@hotmail.com"><img src={logoEmail} alt="#Polexine"/></a>
    </div>
  }
}

class Pagina extends Component{
  constructor(props) {
    super(props);
    this.state = {
      pagina: 'home'
    };
    this.cambiaPagina=this.cambiaPagina.bind(this);
  }

  componentDidMount(){
    document.title = `#Polexine #PolexineTraI2Fiumi #PolexineRisaliLaCorrente #PolexineNotizie #PolexineArticoli #PolexineOltreIlFiume`;
  }

  cambiaPagina(nuovaPagina){
      this.setState({pagina:nuovaPagina});
  }
  
  render(){
    return  <div className="contenitoreGenerale">
              <Header/>
                {this.state.pagina===RIFERIMENTO_PAGINA_HOME&&(<Home aggiornaParent={this.cambiaPagina}/>)
                ||
                this.state.pagina===RIFERIMENTO_PAGINA_NOTIZIE&&(<Notizie aggiornaParent={this.cambiaPagina}/>)
                ||
                this.state.pagina===RIFERIMENTO_PAGINA_ARTICOLI&&(<Articoli aggiornaParent={this.cambiaPagina}/>) 
                ||
                this.state.pagina===RIFERIMENTO_PAGINA_OLTRE&&(<Oltre aggiornaParent={this.cambiaPagina}/>)} 
              <Footer aggiornaParent={this.cambiaPagina}/>
            </div>
  }
}



function App() {
  return  <Pagina/>
}

export default App;
